

// super admin Apis
//dev
const BASE_URL = "https://devsuperadmin.gstore.in"

//live
// const BASE_URL = "https://superadmin.gstore.in"

const PROFILE_URL = "profile/v1/admin"

const TENANT_URL = "tenant/v1/admin"

export const LOGIN = `${BASE_URL}/user/oauth/token`;

export const GET_ALL_BUSINESS_URL = `${BASE_URL}/${TENANT_URL}/business`

export const CREATE_ADDRESS_OF_BUSINESS = `${BASE_URL}/${PROFILE_URL}/address`

export const CREATE_NEW_BUSINESS = `${BASE_URL}/${TENANT_URL}/business`

export const CREATE_NEW_BUSINESS_USER = `${BASE_URL}/user/v1/super-admin/user`

export const REGISTER_BUSINESS_USER = `${BASE_URL}/user/v1/super-admin/user-location`

export const createUserLocation = (tenantId)=> `${BASE_URL}/infrastructure/v1/admin/tenant/${tenantId}/location`

export const USER_LOCATION = `${BASE_URL}/user/v1/admin/user-location`

export const GET_BUSINESS_BY_TENANT_ID =  (tenantId) => {
    return `${BASE_URL}/${TENANT_URL}/business/${tenantId}`
}

export const DELETE_USER_URL = (userId) =>{
    return `${BASE_URL}/${TENANT_URL}/business/${userId}`
}

export const findByEmailUrl = (email) =>{
    return `${BASE_URL}/user/v1/user/find-by-email/${email}`
}